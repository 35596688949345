export const Raven_conf =
  "https://56522fc35e9b4a5db5da92ca6652fd0a@sentry.io/1533391";
export const Version = "0.0.2";
// export const firebaseConfig = {
//   apiKey: "AIzaSyCVVtfr0pWoENIpv67-_PK_KE7D9L0tQjs",
//   authDomain: "panup-4ba27.firebaseapp.com",
//   databaseURL: "https://panup-4ba27.firebaseio.com",
//   projectId: "panup-4ba27",
//   storageBucket: "panup-4ba27.appspot.com",
//   messagingSenderId: "982615458649",
//   appId: "1:982615458649:web:45bbded47dc633c2"
// };
// export const firebaseConfig = {
//   apiKey: "AIzaSyANaoJDIXK-94MICiJmWdSowlNF2VrBKHM",
//   authDomain: "snipper-010.firebaseapp.com",
//   databaseURL: "https://snipper-010.firebaseio.com",
//   projectId: "snipper-010",
//   storageBucket: "snipper-010.appspot.com",
//   messagingSenderId: "863369684354",
//   appId: "1:863369684354:web:da63e4fa07b0372f"
// };

export const firebaseConfig = {
  apiKey: "AIzaSyD_2pelFQpsBASAFseRzEC6craRqvn6yMQ",
  authDomain: "panup-4ba27.firebaseapp.com",
  databaseURL: "https://panup-4ba27.firebaseio.com",
  projectId: "panup-4ba27",
  storageBucket: "panup-4ba27.appspot.com",
  messagingSenderId: "982615458649",
  appId: "1:982615458649:web:45bbded47dc633c2"
};
