import React  from "react";
import { Router, Switch, Route } from "react-router-dom";  
import LoginView from "./Screens/login";
import Home from "./Screens/home";
import New from "./Screens/new";
import Snip from "./Screens/snip"; 

export default function AppRouter(props) {
 
  return  <Router history={props.history}>
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={New} path="/new" />
        <Route component={Snip} path="/snip/:id" />
        <Route component={LoginView} path="/login" />
      </Switch>
    </Router> 
}
