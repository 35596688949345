import React from 'react'  
// Packages
import { Component } from 'react' 
import Progress from 'nprogress'
import {withRouter} from 'react-router-dom'
class Page extends Component {
    constructor() {
    super()
    this.state={progress:''}
    this.handleKeypress = this.handleKeypress.bind(this)
}
componentWillMount(){
    this.setState({progress : setTimeout(Progress.start, 200)})
}

componentDidMount() {
    document.addEventListener('keydown', this.handleKeypress, true)
    clearTimeout(this.state.progress)
    Progress.done()
}

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeypress, true)
  }

  handleKeypress(event) {
    if (event.keyCode === 27) {
        debugger
      return this.props.history.push({
        pathname: '/home' 
      })
    }

    if ((event.ctrlKey || event.metaKey) && event.keyCode === 78) {
      return this.props.history.push('/add')
    }
 
  }

  render() {
    const { children } = this.props
    return (
      <main>
        {children}
        <style jsx= "true" global="true">{`
          
          a {
            text-decoration: none;
          }
          li {
            list-style: none;
          }
          img {
            max-width: 100%;
          }
          fieldset {
            border: 0;
          }
          #nprogress {
            pointer-events: none;
          }
          #nprogress .bar {
            background:  white ;
            position: fixed;
            z-index: 1031;
            top: 0;
            left: 0;
            width: 100%;
            height: 2px;
          }
          #nprogress .peg {
            display: block;
            position: absolute;
            right: 0px;
            width: 100px;
            height: 100%;
            box-shadow: 0 0 10px white , 0 0 5px  white ;
            opacity: 1;
            transform: rotate(3deg) translate(0px, -4px);
          }
        `}</style>
      </main>
    )
  }
}

export default withRouter(Page)